import React from 'react';
import { Heading, Text, Box, Flex } from 'rebass';

export const FourPoints = ({ text, number }) => (
  <Flex bg={'blueDark'} minHeight={'125px'} mb={'6px'}>
    <Flex width={1 / 4} alignItems="center" pl={[1, '8%']}>
      <Box
        width={64}
        height={64}
        sx={{
          borderStyle: 'solid',
          borderRadius: '50%',
          borderWidth: '2px',
          borderColor: 'gold',
        }}
      >
        <Heading
          mt={'6px'}
          as="h3"
          textAlign="center"
          fontSize={5}
          fontWeight={'400'}
          color={'gold'}
        >
          {number}
        </Heading>
      </Box>
    </Flex>
    <Flex width={3 / 4} alignItems="center" pr={[1, '10%']}>
      <Text pl={[1, 0]} fontSize={3}>
        {text}
      </Text>
    </Flex>
  </Flex>
);
