import React, { Fragment } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import SEO from '../components/SEO';
import {
  Main,
  Section,
  Container,
  Btn,
  LinkBtn,
  LinkBtnRed,
} from '../components/Elements';
import Header from '../components/Header';
import FooterPre from '../components/FooterPre';
import { Hero } from '../components/HeroPages';
import Form from '../components/ContactLong';
import { FourPoints } from '../components/FourPoints';
import { Heading, Flex, Box, Text, Image } from 'rebass';

import TabView from '../components/Tabs';

import _map from 'lodash/map';
import NetlifyForm3 from '../components/FormNetlify3';

/* Todo: https://github.com/datocms/gatsby-source-datocms#modular-content-fields */

const Talo = () => {
  const data = useStaticQuery(taloQuery);
  const {
    seoMetaTags,
    content,
    heroLayer,
    imgHero,
    lead,
    title,
    otsikko1,
    teksti1,
    kuva1,
    otsikko2,
    teksti2,
    kuva2,
    otsikko3,
    teksti3,
    kuva3,
    lataaEsiteBtn,
    lataaEsiteTeksti,
    lataaEsitePdf,
    valahdyksiaOtsikko,
  } = data.datoCmsTalo;
  const { tausta2, tausta3 } = data.datoCmsSivusto;
  const { goodPoints } = data.datoCmsHighlight;

  const introLayer = 'rgba(129, 173, 179, 0.8)';

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <Header />
      <Main bg={'turguoiseLightBg'} color={'blueDark'} textAlign="left" pb={6}>
        <Hero
          title={title}
          lead={lead}
          imgHero={imgHero}
          introLayer={introLayer}
          heroLayer={heroLayer}
          introColor={'blueDark'}
        />
        <Section>
          <Container textAlign="center" flexDirection="column">
            <Text
              mx={'auto'}
              py={5}
              maxWidth={720}
              dangerouslySetInnerHTML={{
                __html: data.datoCmsTalo.content,
              }}
            />
          </Container>

          <Container flexWrap="wrap">
            <Flex
              width={[1, 1 / 2]}
              my={2}
              flexDirection="column"
              justifyContent="center"
            >
              <Heading fontSize={6} maxWidth={400}>
                {otsikko1}
              </Heading>
              <Text my={2} maxWidth={445}>
                {teksti1}
              </Text>
            </Flex>
            <Flex width={[1, 1 / 2]} my={2} sjustifyContent="flex-end">
              <Box width={'100%'} maxWidth={'536px'}>
                <Img fluid={kuva1.fluid} alt={kuva1.alt} width={536} />
              </Box>
            </Flex>
          </Container>

          <Container flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Flex width={[1, 1 / 2]} my={2} justifyContent="flex-start">
              <Box width={'100%'} maxWidth={'536px'}>
                <Img fluid={kuva2.fluid} alt={kuva2.alt} width={536} />
              </Box>
            </Flex>
            <Flex
              width={[1, 1 / 2]}
              my={2}
              flexDirection="column"
              pl={[0, '8%']}
              justifyContent="center"
            >
              <Heading fontSize={6} maxWidth={400}>
                {otsikko2}
              </Heading>
              <Text my={2} maxWidth={445}>
                {teksti2}
              </Text>
            </Flex>
          </Container>

          <Container flexWrap="wrap">
            <Flex
              width={[1, 1 / 2]}
              my={2}
              flexDirection="column"
              justifyContent="center"
            >
              <Heading fontSize={6} maxWidth={400}>
                {otsikko3}
              </Heading>
              <Text my={2} maxWidth={445}>
                {teksti3}
              </Text>
            </Flex>
            <Flex width={[1, 1 / 2]} my={3} justifyContent="flex-start">
              <Box width={'100%'} maxWidth={'536px'}>
                <Img fluid={kuva3.fluid} alt={kuva3.alt} width={536} />
              </Box>
            </Flex>
          </Container>
          <Section py={6} bg="turquoise" color="#A6C0C2">
            <Container
              className="kerrospohjatTalo"
              flexDirection="column"
              textAlign="center"
            >
              <TabView kerrospohjat={data.floors} />
              <div
                css={`
                  display: none;
                `}
              >
                <Heading
                  fontSize={6}
                  textAlign="center"
                  maxWidth={'720px'}
                  mt={7}
                  mx="auto"
                  color="#fff"
                >
                  {data.datoCmsLataaEsite.otsikko}
                </Heading>
                <Text
                  color="#fff"
                  maxWidth={630}
                  mx={'auto'}
                  mt={3}
                  sx={{ zIndex: 1 }}
                >
                  {data.datoCmsLataaEsite.teksti}
                </Text>
                <a
                  href={data.datoCmsLataaEsite.esite.url}
                  target="_blank"
                  className="downloadLink"
                  css={{
                    marginTop: 20,
                    textDecoration: 'underline',
                    color: '#fff',
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z"
                      fill="#fff"
                    />
                  </svg>
                  Lataa kohde-esite (PDF)
                </a>
                <a
                  href={data.datoCmsLataaEsite.esite2.url}
                  target="_blank"
                  className="downloadLink"
                  css={{
                    marginTop: 10,
                    textDecoration: 'underline',
                    color: '#fff',
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z"
                      fill="#fff"
                    />
                  </svg>
                  Lataa sisustustyylikooste (PDF)
                </a>
              </div>
              {/*<NetlifyForm3
                urlEsite={data.datoCmsLataaEsite.esite.url}
                urlEsiteSisustus={data.datoCmsLataaEsite.esite2.url}
              />*/}
            </Container>
          </Section>
          <Heading mt={6} fontSize={6} sx={{ zIndex: 1 }} textAlign="center">
            {valahdyksiaOtsikko}
          </Heading>
          <Container flexWrap="wrap" sx={{ zIndex: 1 }} textAlign="center">
            {_map(goodPoints, goodPoints => (
              <Box
                key={goodPoints.id}
                pt={4}
                pb={[0, 3]}
                px={2}
                width={[1, 1 / 5]}
                sx={{ zIndex: 1 }}
              >
                <Box height={60}>
                  <Image src={goodPoints.iconDark.url} width={40} />
                </Box>

                <Text
                  fontSize={0}
                  fontWeight={600}
                  letterSpacing={'2px'}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {goodPoints.text}
                </Text>
              </Box>
            ))}
          </Container>
        </Section>
      </Main>
      <FooterPre />
    </Fragment>
  );
};

const taloQuery = graphql`
  {
    datoCmsSivusto {
      tausta2 {
        url
      }
      tausta3 {
        url
      }
    }
    datoCmsHighlight {
      goodPoints {
        id
        text
        iconDark {
          url
        }
      }
    }
    datoCmsLataaEsite {
      otsikko
      teksti
      esite {
        url
      }
      esite2 {
        url
      }
    }
    floors: allDatoCmsKerrospohjat(sort: { fields: position, order: ASC }) {
      edges {
        node {
          id
          otsikko
          otsikkoMobiilissa
          kuva {
            fluid(maxWidth: 1800) {
              ...GatsbyDatoCmsFluid
            }
            url
            alt
          }
        }
      }
    }
    datoCmsTalo {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      lead
      content

      heroLayer {
        alpha
        blue
        green
        red
      }
      imgHero {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      otsikko1
      teksti1
      kuva1 {
        fluid(maxWidth: 536, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      otsikko2
      teksti2
      kuva2 {
        fluid(maxWidth: 536, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      otsikko3
      teksti3
      kuva3 {
        fluid(maxWidth: 536, imgixParams: { fm: "png", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      valahdyksiaOtsikko
    }
  }
`;

export default Talo;
