import React from 'react';
import { Heading, Text, Box, Flex, Button } from 'rebass';
import { Label, Input, Textarea, Radio, Checkbox } from '@rebass/forms';

const Form = () => {
  return (
    <Box pb={3}>
      <form
        name="Tilaratkaisu Lomake"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <Flex
          flexDirection="column"
          maxWidth={320}
          mx={'auto'}
          color={'beigeText'}
        >
          <Box width={1} px={2} mb={0} className="required">
            <Input
              required
              id="company2"
              name="company"
              type="text"
              placeholder="Yrityksen nimi"
              bg={'blueDark'}
              sx={{ borderColor: 'turquoise' }}
            />
          </Box>
          <Box width={1} px={2} mb={0} className="required">
            <Input
              required
              id="companyNumber2"
              name="companyNumber"
              type="text"
              placeholder="Y-tunnus"
              bg={'blueDark'}
              sx={{ borderColor: 'turquoise' }}
            />
          </Box>
          <Box width={1} px={2} mb={0} className="required">
            <Input
              required
              id="name2"
              name="name"
              type="text"
              placeholder="Yhteyshenkilön nimi"
              bg={'blueDark'}
              sx={{ borderColor: 'turquoise' }}
            />
          </Box>
          <Box width={1} px={2} mb={0} className="required">
            <Input
              required
              id="email2"
              name="email"
              type="email"
              placeholder="Sähköposti"
              bg={'blueDark'}
              sx={{ borderColor: 'turquoise' }}
            />
          </Box>
          <Box width={1} px={2} mb={0} className="required">
            <Input
              required
              id="phone2"
              name="phone"
              type="text"
              placeholder="Puhelin"
              bg={'blueDark'}
              sx={{ borderColor: 'turquoise' }}
            />
          </Box>
          <Box width={1} px={2} mb={1}>
            <Textarea
              id="message2"
              name="message"
              placeholder="Lisätietoja"
              bg={'blueDark'}
              sx={{ borderColor: 'turquoise' }}
            />
          </Box>
          <Box px={2} mb={0}>
            <Button type="submit" bg={'gold'} color={'white'} width={200}>
              Lähetä
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};

export default Form;
