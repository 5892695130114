import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import BackgroundImage from 'gatsby-background-image';
import { Heading, Text, Box, Flex } from 'rebass';
import { Btn } from '../components/Elements';
import Fade from 'react-reveal/Fade';
import scrollTo from 'gatsby-plugin-smoothscroll';

const BackgroundSection = ({
  title,
  lead,
  imgHero,
  heroLayer,
  introLayer,
  introColor,
  className,
}) => {
  const red = heroLayer.red;
  const blue = heroLayer.blue;
  const green = heroLayer.green;
  const alpha = heroLayer.alpha / 256;

  return (
    <Box
      css={`
        .heroImage {
          background-position: bottom;
        }
        h1 {
          text-shadow: 0px 0px 20px rgba(255, 255, 255, 1);
          @media (max-width: 600px) {
            text-shadow: none;
            text-align: center;
          }
        }
      `}
      display={'flex'}
      flexDirection="column"
      alignItems={'center'}
      width={'100%'}
    >
      <BackgroundImage
        Tag="section"
        fluid={imgHero.fluid}
        className={className}
      >
        <Flex
          minHeight={['50vh', '100vh', '100vh']}
          justifyContent="flex-end"
          sx={{
            flexDirection: 'column',
            textAlign: 'center',
            width: '100%',
            height: '100%',
            backgroundColor:
              'rgba(' + red + ',' + green + ',' + blue + ',' + alpha + ')',
          }}
        >
          <Flex
            className="hideOnMobile"
            flexDirection="column"
            justifyContent="center"
            sx={{ flexGrow: 1 }}
          >
            <Heading
              as="h1"
              mx={'auto'}
              mt={'70px'}
              fontWeight={600}
              maxWidth={'720px'}
              letterSpacing={'6px'}
              fontSize={[5, 6, 7, 7]}
              textAlign="center"
              sx={{ textTransform: 'uppercase', cursor: 'pointer' }}
              onClick={() => scrollTo('#mainContent')}
            >
              {title}
            </Heading>

            <div className="arrowBox" onClick={() => scrollTo('#mainContent')}>
              <svg
                className="shadow"
                width="60"
                height="28"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.59 0.589966L6 5.16997L1.41 0.589966L0 1.99997L6 7.99997L12 1.99997L10.59 0.589966Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </Flex>
        </Flex>
      </BackgroundImage>
      <Flex id="mainContent" width={1} bg={introLayer}>
        <Box width={1} px={1} py={4} pb={[0, 3]} justifyContent="center">
          <Heading fontSize="32px" className="heroPagesMobileTitle" as="h1">
            {title}
          </Heading>
          <Text
            py={[4, 3]}
            pb={[0, 3]}
            color={introColor}
            lineHeight={1.44}
            fontSize={['20px', 4, 5]}
            mx={'auto'}
            textAlign="center"
            maxWidth={'960px'}
            fontWeight={[600, 400]}
            fontFamily={['acumin-pro', 'heading']}
          >
            {lead}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export const Hero = styled(BackgroundSection)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  width: 100%;
  height: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
`;
