import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Heading, Flex, Box, Text, Image, Button } from 'rebass';
import Img from 'gatsby-image';
import _map from 'lodash/map';
import TransitionsModal from './ModalFloors';

class TabsView extends React.Component {
  constructor() {
    super();
    this.state = { tabIndex: 0 };
  }

  render() {
    return (
      <Tabs
        className="floors"
        selectedIndex={this.state.tabIndex}
        onSelect={tabIndex => this.setState({ tabIndex })}
      >
        <TabList>
          {this.props.kerrospohjat.edges.map(post => (
            <Tab key={post.node.id}>
              <Text display={['none', 'none', 'inline-block']}>
                {post.node.otsikko}
              </Text>
              <Text display={['inline-block', 'inline-block', 'none']}>
                {post.node.otsikkoMobiilissa}
              </Text>
            </Tab>
          ))}
        </TabList>
        {this.props.kerrospohjat.edges.map(post => (
          <TabPanel key={post.node.id}>
            <TransitionsModal img={post.node.kuva} />
          </TabPanel>
        ))}
      </Tabs>
    );
  }
}

export default TabsView;
